import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Call useNavigate to get the navigate function

  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_DEV // Development API URL
    : process.env.REACT_APP_API_URL; // Production API URL


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("apiBaseUrl", apiBaseUrl);
      // const response = await axios.post('https://unified-backend-38e9761596ff.herokuapp.com/users/login', {
      const response = await axios.post(`${apiBaseUrl}/users/login`, {
        username,
        password
      }, {
        withCredentials: true // Ensure cookies are sent with the request
      });

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.username);
        localStorage.setItem('skipSalesforceSetup', JSON.stringify({[response.data.username]: false}));
        onLoginSuccess();

      navigate('/console/dashboard');
    } catch (error) {
      alert('Login failed');
    }
  };


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          {/* <svg className="w-12 h-12 text-indigo-500" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10z"/>
            <path d="M12 5.5c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm0 11c-2.481 0-4.5-2.019-4.5-4.5S9.519 7.5 12 7.5s4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5z"/>
          </svg> */}
        </div>
        <h2 className="text-2xl font-bold text-center text-white mb-6">Sign in to your account</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-400 mb-2" htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-400 mb-2" htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-right mt-2">
              <a className="text-sm text-indigo-400 hover:text-indigo-300" href="#">Forgot password?</a>
            </div>
          </div>
          <button
            type="submit"
            className="w-full p-3 rounded bg-indigo-500 text-white font-bold hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Sign in
          </button>
        </form>
        <p className="text-center text-gray-400 mt-6">
          Don't have an account?{' '}
          <Link to="/signup" className="text-indigo-400 hover:text-indigo-300">
            Sign up
          </Link>
        </p>
        {/* <p className="text-center text-gray-400 mt-6">Not a member? <a className="text-indigo-400 hover:text-indigo-300" href="#">Start a 14 day free trial</a></p> */}
      </div>
    </div>
  );
}

export default Login;
